<script lang="ts">
	import { override } from '$src/utils/classnames'
	import { createEventDispatcher } from 'svelte'
	import { twMerge } from 'tailwind-merge'

	export let className = ''
	export { className as class }
	export let isDisabled: boolean = false

	const dispatch = createEventDispatcher<{
		click: void
	}>()

	function handleClick() {
		dispatch('click')
	}

	$: computedClassName = twMerge(
		override(
			'dark:bg-brand-gray-4/10 border-brand-gray-4 dark:hover:bg-brand-gray-4/20 border dark:text-white text-black transition-all px-3 py-1 text-xs rounded-md flex items-center',
			className,
		),
	)
</script>

<button disabled={isDisabled} class={computedClassName} on:click={handleClick}>
	<slot />
</button>
